import React, { Component } from 'react';
import Keys from '../../../constants/keys'
import Utils from "../../../utils"
import Title from "../../Title"
import styles from "../../../css/email.module.css"


export class EmailForm extends Component {
  constructor() {
    super();
    this.state = { message: '', email: '' };
  }

  _handleSubmit(e) {
    e.preventDefault();
    console.log(e.target.elments)
    if(!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.state.email)) {
      this.setState({message: "Please provide a valid email address"})
      return
    }
    window.grecaptcha.execute(Keys.recaptcha_site_key, {action: 'wlsignup'}).then((token) => {
      return fetch("/api/waitlist", {
        method: "POST",
        body: JSON.stringify({email: this.state.email, recaptcha_token: token}),
        headers: {
          'Content-Type': 'application/json',
          "X-CSRFToken": Utils.getCookie('csrftoken'),
        }
      });
    }).then((res) => res.json()).then((response) => {
      if(response.ok) {
        this.setState({ message: 'Thank you! You will receive an invite link via email when a spot opens up!' });
        console.log('Success:', response)
      } else {
        this.setState({message: 'An error has occurred.'})
        console.log('Error:', response)
      }
    })
  };

  render() {
    const { message } = this.state;
    const handleChange = (e) => {
      let state = {};
      state[e.target.name] = e.target.value;
      this.setState(state)
    }
    return (
    <section className={styles.email}>
        <span className={styles.modalHeader}><Title title="Invite" subtitle="Required" /></span>
        <p className={styles.instructionsModal}>Rivet currently requires an invite code. You either have not provided one, or provided an expired one. Use the form below to get on our waiting list for an invite.</p>
        <div className={styles.center}>
      <form id="signup-form" className={styles.form} onSubmit={this._handleSubmit.bind(this)}>
        <div className={styles.twerkWrapper}>
        <input
          type="email"
          name="email"
          id="email"
          onChange={handleChange}
          value={this.state.email}
          placeholder="Email Address"
          className={styles.formControl}
        />
        <input type="submit" value="Sign Up" className={styles.submit} /></div>
        <div className={styles.feedbackWrapper}><div className={`${message ? 'visible success' : ''} message`}></div>
          {message}
        </div>
      </form>
      </div>
        </section>
    );
  }
}

export default EmailForm;
